export const AboutData = {
  description: "Hello world! My name is Yilla, and I'm a full-stack developer born and raised in NYC. Formerly a writer, I transitioned into software engineering because I love challenging projects, strategizing solutions and connecting with people. I'm passionate about making technology more equitable, accessible, and diverse to as many communities as possible.<br /><br />When I'm not surfing the web 🏄, I stay active by practicing hot yoga and pilates, working on an art or home DIY project, and <a href='https://readingpartners.org' target='_blank'>volunteering</a> to help improve literacy for young readers.",
  skills: {
    proficient: ['Javascript', 'HTML5 / CSS3', 'TypeScript', 'React', 'React Native', 'Redux', 'Node.js', 'Express.js', 'Tailwind CSS', 'Sass', 'GraphQL', 'Vue.js', 'PostgreSQL', 'Sequelize.js (SQL ORM)',],
    learning: [],
  },
  contact: [
    { title: 'Email', content: 'work@yillachen.com' },
    { title: 'Based In', content: 'Brooklyn, NY' },
    { title: 'MBT', content: 'ENFJ' },
    { title: 'Star Sign', content: 'Leo ♌' },
  ]
};
