import { Link } from 'react-router-dom';
import React from 'react';
import IPage from '../../interface/page';
import { motion } from 'framer-motion';
import { fadeTransition, fadeVariant } from '../../config/transition';
import { AboutData } from '../../config/aboutData';

const About: React.FunctionComponent<IPage> = props => {
  const { description, skills, contact } = AboutData

  return (
    <motion.div animate="in" initial="out" exit="out" variants={fadeVariant} transition={fadeTransition}>
      <div className="container space-y-8 py-8 text-sm md:text-base">
        <div className="space-y-4">
          <h2 className="font-serif text-lg text-soot">About</h2>
          <div className="about-body space-y-4">
            <p className="text-soot" dangerouslySetInnerHTML={{ __html: description }}>
            </p>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 border-t border-berry">
          <div className="space-y-4 md:border-none border-b border-berry py-8">
            <h2 className="font-serif text-lg text-soot">Contact</h2>
            <ul className="grid grid-cols-2 gap-4 md:gap-6 list-none">
              {contact.map((attribute, idx) => (
                <li key={idx}>
                  <h3 className="font-mono text-sm pb-2 uppercase text-berry">{attribute.title}</h3>
                  {attribute.content}
                </li>
              ))}
            </ul>
          </div>

          <div className="space-y-4 md:py-8">
            <h2 className="font-serif text-lg text-soot">Skills</h2>
            <div className={`list-none grid grid-cols-1 md:${skills.learning.length > 0 ? 'grid-cols-2' : 'grid-cols-1'} gap-8`}>
              <div>
                <h3 className="font-mono text-sm pb-2 uppercase text-berry">Proficient</h3>
                <ul className="grid grid-cols-2 gap-x-4 md:gap-x-6">
                  {skills.proficient.map((skill, idx) => (
                    <li key={idx}>{skill}</li>
                  ))}
                </ul>
              </div>

              {skills.learning.length > 0 &&
                <div>
                  <h3 className="font-mono text-sm pb-2 uppercase text-berry">Learning</h3>
                  <ul>
                    {skills.learning.map((skill, idx) => (
                      <li key={idx}>{skill}</li>
                    ))}
                  </ul>
                </div>}
            </div>
          </div>
        </div>

        <div className="flex w-full justify-end">
          <Link to="/" className="flex flex-row items-center justify-end text-sm gap-2">
            <svg width="6" height="10" viewBox="0 0 6 10" xmlns="http://www.w3.org/2000/svg">
              <path d="M5.737 1.05 1.787 5l3.95 3.95-.707.707L.373 5 5.03.343l.707.707z" fill="#8f2d56" fillRule="evenodd" />
            </svg>
            <span className=" font-mono text-sm text-berry uppercase">Back to Projects</span>
          </Link>
        </div>
      </div>
    </motion.div>
  );
};

export default About;
