import React from 'react';
import { ListProps } from '../../../interface/project';
import AnchorArrowSoot from '../../images/AnchorArrowSoot.svg';

const CreditList = (props: ListProps) => {
  const { data } = props;

  return (
    <ul className={`list-none grid ${data && data.length > 2 && 'grid-cols-2'} md:grid-cols-1 text-sm md:text-base items-start`}>
      {data &&
        data.map((credit: any, idx: number) => (
          <li key={idx} className="flex justify-start items-center gap-2">
            {credit.url ? (
              <span className="flex text-sm md:text-base justify-end items-center gap-2 group">
                <a href={credit.url} target="_blank" rel="noreferrer">
                  {credit.title}
                </a>
                <img src={AnchorArrowSoot} alt={credit.title} className="group-hover:pb-2 group-hover:duration-200" />
              </span>
            ) :
            credit.title
          }
          </li>
        ))}
    </ul>
  );
};

export default CreditList;
