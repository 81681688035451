import About from "../client/components/About";
import IRoute from "../interface/route";
import Home from "../client/components/Home";

const routes: IRoute[] = [
  {
    path: "/",
    name: "Home",
    component: Home,
    exact: true,
  },
  {
    path: "/about",
    name: "About",
    component: About,
    exact: true,
  }
];

export default routes;
