import React from 'react';
import { ListProps } from '../../../interface/project';

const IconList = (props: ListProps) => {
  const { data } = props;

  return (
    <div className="flex flex-row justify-start md:justify-center flex-wrap gap-2 pt-4 md:pt-0">
      {data.map((icon, idx) => (
        <img key={idx} alt={icon.altText && icon.altText} src={icon.imageUrl} className="w-7 h-7" loading="lazy" />
      ))}
    </div>
  );
};

export default IconList;
