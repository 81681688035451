import React, { useState, useRef, useEffect } from 'react';
import { ProjectComp, ProjectLink } from '../../../interface/project';
import { FeaturedTitle, CreditList, IconList } from './index';
import AnchorArrowCrimson from '../../images/AnchorArrowCrimson.svg';
import Close from '../../images/Close.svg'

const Project = (props: ProjectComp) => {
  const { videos, images, credits, icons, info, feature } = props.data;

  const accordion = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(props.open);
  const [height, setHeight] = useState(0);

  const toggleAccordion = () => {
    setOpen(!open);
    setHeight(open ? 0 : 5000);
  };

  useEffect(() => {
    if (open) {
      const contentEl = accordion.current as HTMLDivElement;
      setHeight(contentEl.scrollHeight)
    } else {
      setHeight(0);
    }
  }, [open])

  return (
    <section id={info.title} className="border-berry border-t">
      <button onClick={toggleAccordion} className="w-full text-left">
        <FeaturedTitle title={info.title} snippet={feature.snippet} type={feature.type} />
      </button>

      <div ref={accordion} className="container text-center overflow-hidden duration-300 ease-in space-y-4 md:space-y-0" style={{ maxHeight: `${height}px` }}>
        <div className="flex flex-col-reverse md:grid md:grid-cols-2 gap-4 md:gap-8">
          <div className="space-y-4">
            {images && (
              <div className={`flex flex-col md:gap-2 ${images.length > 1 && 'md:grid md:grid-cols-2'}`}>
                {images.map((image, idx) => (
                  <a key={idx} href={image.imageUrl} target="_blank" rel="noreferrer" className="project-image">
                    <img className={`my-2 md:w-full ${images && !videos && ''}`} src={image.imageUrl} alt={image.altText} loading="lazy" />
                  </a>
                ))}
              </div>
            )}

            {videos && (
              <div className="flex flex-col gap-4 md:gap-8">
                {videos.map((video, idx) => (
                  <div className="project-video relative pb-[56.25%]" key={idx}>
                    <iframe className="absolute top-0 left-0 w-full h-full" title={`${info.title} Demo Video`} src={video.videoUrl} loading="lazy"></iframe>
                  </div>
                ))}
              </div>
            )}
          </div>

          <div className="space-y-4 md:space-y-8">
            <p className="project-body pt-4 md:pt-0 text-sm md:text-base leading-normal text-left" dangerouslySetInnerHTML={{ __html: info.description }}></p>

            <div className="hidden md:flex justify-center md:justify-between">
              {icons && <IconList data={icons} />}
              {info.url && (
                <span className="flex text-sm md:text-base justify-end items-center gap-2 group">
                  <SeeItLive info={info} />
                </span>
              )}
            </div>

            {credits && (
              <div className="hidden md:block text-left">
                <span className="font-mono text-sm leading-normal uppercase text-berry text-left">Credits</span>
                <CreditList data={credits} />
              </div>
            )}
          </div>
        </div>

        <div className="block md:hidden">
          {credits && (
            <div className="text-left">
              <span className="font-mono text-sm leading-normal uppercase text-berry text-left">Credits</span>
              <CreditList data={credits} />
            </div>
          )}
          {icons && <IconList data={icons} />}
        </div>

        <div className={`flex flex-row ${info.url ? 'justify-between' : 'justify-end'} gap-4 py-6 text-sm md:text-base md:justify-end`}>
          {info.url && (
            <span className="flex items-center gap-2 md:hidden group">
              <SeeItLive info={info} />
            </span>
          )}
          <button onClick={toggleAccordion} className="flex flex-row items-center gap-2 group">
            <span className=" font-mono text-sm uppercase text-berry">Close</span>
            <img src={Close} alt="Close Accordion" className="group-hover:rotate-180 group-hover:duration-200 group-hover:ease-in-out" />
          </button>
        </div>
      </div>
    </section>
  );
};

const SeeItLive = (props: ProjectLink) => {
  const { info } = props;
  return (
    <>
      <a href={info.url} className=" no-underline font-mono text-sm text-berry uppercase" target="_blank" rel="noreferrer">
        See it Live
      </a>
      <img src={AnchorArrowCrimson} alt={`Go live to ${info.title}`} className="group-hover:pb-2 group-hover:duration-200" />
    </>
  )
}

export default Project;
