import React from "react";

export default function Loading() {
  return (
    <div className="h-[100vh] flex items-center justify-center">
      <div className="stage">
        <div className="dot-collision"></div>
      </div>
      <div className="fixed inset-0 bg-gradient-to-t from-berry/10 w-screen h-screen -z-[1]"></div>
    </div>
  );
}
