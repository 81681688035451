import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { slideTransition, slideVariant } from '../../../config/transition';

const FeaturedTitle = (props: any) => {
  const { title, snippet, type } = props;
  const [show, setAccordion] = useState(false);
  return (
    <div className="container flex justify-between items-center space-x-4 h-20 lg:h-28" onMouseEnter={() => setAccordion(true)} onMouseLeave={() => setAccordion(false)}>
      <h2 className="font-mono text-soot text-lg md:text-xxl lg:text-3xl uppercase tracking-tight">{title}</h2>
      {show ? (
        <motion.div animate="in" initial="out" exit="out" variants={slideVariant} transition={slideTransition}>
          <h3 className="text-sm md:text-base font-sansSerif text-soot text-right">{snippet}</h3>
        </motion.div>
      ) : (
        <h3 className="text-sm md:text-base font-sansSerif text-soot text-right">{type}</h3>
      )}
    </div>
  );
};

export default FeaturedTitle;
