import React from 'react';
import { NavLink } from 'react-router-dom';
import IPage from '../../interface/page';
import HomeIcon from '../images/home.svg';

const Navbar: React.FunctionComponent<IPage> = props => {
  const active = {
    color: '#8f2d56',
  };

  return (
    <nav className="nav-bar flex flex-row items-center py-4 border-b border-berry w-screen fixed top-0 left-0 bg-millet/75 z-20">
      <div className="navigation container w-full flex justify-between items-center">
        <NavLink to="/" activeStyle={active} className="home-icon">
          <img src={HomeIcon} alt="Go back to Home" className="w-4 h-4" />
        </NavLink>
        <div className="flex justify-between space-x-4">
          <NavLink to="/about" activeStyle={active}>
            <p className="font-mono md:text-md uppercase">About</p>
          </NavLink>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
