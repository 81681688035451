import Liquid from '../client/images/shopify-liquid.png';
import MeshGif from '../client/images/mesh.gif';
import Hearth1 from '../client/images/hearth-1.png';
import Hearth2 from '../client/images/hearth-2.png';
import Revelry1 from '../client/images/revelry-desktop-nav.gif';
import Revelry2 from '../client/images/revelry-nav-mobile.gif';

export const RevelryData = {
  images: [{ imageUrl: Revelry2, altText: 'Revelry Navigation - Mobile' }, { imageUrl: Revelry1, altText: 'Revelry Navigation — Desktop' }],
  icons: [
    { imageUrl: 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/javascript/javascript-original.svg', altText: 'Javascript' },
    { imageUrl: 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/react/react-original.svg', altText: 'React' },
    { imageUrl: Liquid, altText: 'Shopify Liquid' },
    { imageUrl: 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/vuejs/vuejs-original.svg', altText: 'Vue.js'}
  ],
  credits: [{ title: 'Yilla Chen, Frontend Developer' }, { title: 'Verbal+Visual', url: 'https://verbalplusvisual.com' }],
  feature: { snippet: 'Fashion-forward and body-friendly bridal wear.', type: 'Shopify+ E-Commerce' },
  info: {
    title: 'Revelry',
    description:
      "Revelry is a chic and accessible bridal wear brand that offers made-to-order garments, and home try-ons for wedding parties to look and feel confident.<br /><br />I was tasked to redesign and develop Revelrys global navigation bar, one of the most utilized features on an e-commerce website, to improve content-management and allow more marketing freedom.<br /><br />The new design features reusable mega-menu components (extended into three variations) for a customized dynamic client experience on both mobile and desktop viewpoints.<br/><br/>Other features implemented: global wishlist, rush fee delivery, dynamic YMAL recommendation carousels, and international e-commerce.",
    url: 'https://shoprevelry.com',
  },
};

export const MStarsData = {
  videos: [{ videoUrl: 'https://player.vimeo.com/video/766673092' }],
  icons: [
    { imageUrl: 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/javascript/javascript-original.svg', altText: 'Javascript' },
    { imageUrl: 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/react/react-original.svg', altText: 'React Native' },
    { imageUrl: Liquid, altText: 'Shopify Liquid' },
  ],
  credits: [{ title: 'Yilla Chen, Developer' }, { title: 'Verbal+Visual', url: 'https://verbalplusvisual.com' }],
  feature: { snippet: 'Locally made high-quality clothing staples.', type: 'Shopify+ E-Commerce' },
  info: {
    title: 'Michael Stars',
    description:
      'Michael Stars is a low-waste LA-based minimalistic clothing brand that came to Verbal+Visual for a website refresh and maintanence.<br/><br/>We modernized collection pages using the Searchspring APIs machine-learning engine to better market product merchandising and recommendations. Leveraged Yotpo API to capture/display customer reviews and I created custom filter/sort methods.<br/><br/>Other features implemented: story-telling carousels; loyalty program using Yotpo; custom reusable UI components library; accessibility programming; and global cart.',
    url: 'https://michaelstars.com',
  },
};

export const NSCData = {
  videos: [{ videoUrl: 'https://player.vimeo.com/video/770775225' }],
  icons: [
    { imageUrl: 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/javascript/javascript-original.svg', altText: 'Javascript' },
    { imageUrl: 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/react/react-original.svg', altText: 'React' },
    { imageUrl: 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/sass/sass-original.svg', altText: 'Sass' },
  ],
  credits: [{ title: 'Yilla Chen, Developer' }, { title: 'Christopher Kemeza, Lead Developer', url: 'https://github.com/kemtopher' }, { title: 'Night Snack Club', url: 'https://nightsnackclub.com' }],
  feature: { snippet: 'Showcasing contributors & artists of 2020', type: 'Interactive Website' },
  info: {
    title: 'Night Snack Club',
    description:
      "2020 Year in Review is an interactive animated website that highlights the artists and writers who contributed to <a href='https://nightsnackclub.com'>nightsnackclub.com</a> to express creativity and share knowledge, during the beginning of the COVID pandemic in 2020.<br /><br />The website is designed by Night Snack Club, and executed with React and Sass for a fluid dynamic experience with low loading time.",
    url: 'https://nscyearinreview.com',
  },
};

export const MeshData = {
  videos: [{ videoUrl: 'https://player.vimeo.com/video/678746712?h=3bfd78efaf' }],
  images: [{ imageUrl: MeshGif }],
  icons: [
    { imageUrl: 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/javascript/javascript-original.svg', altText: 'Javascript' },
    { imageUrl: 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/react/react-original.svg', altText: 'React Native' },
    { imageUrl: 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/firebase/firebase-plain.svg', altText: 'Firebase' },
    { imageUrl: 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/google/google-original-wordmark.svg', altText: 'Google Maps API' },
  ],
  credits: [{ title: 'Yilla Chen, Developer' }, { title: 'Claudia Flores, Developer' }, { title: 'Josephine Simple, Developer' }, { title: 'Maxiel Mrvaljevic, Developer' }, { title: 'Fullstack Academy', url: 'https://fullstackacademy.com' }],
  feature: { snippet: 'A personal safety app that texts your contacts en route', type: 'Mobile Application' },
  info: {
    title: 'Mesh',
    description:
      'A mobile safety application designed to find the safest route to your destination using the Google Maps API with heatmap data that contains over 300K reported incidents from the NYC public crime database. <br/><br/> Users are able to create a trusted group of contacts, called Safety Nets, who receive notifications of your journeys and SOS signals. <br/><br/> In case of emergency, users can tap on the in-app SOS button, which dims the screen, and sends a pinned location to their default Safety Net. This information is logged in their account and can be used for police reports.',
    url: 'https://github.com/Whole-New-WWW/capstone-mesh',
  },
};

export const HearthData = {
  images: [{ imageUrl: Hearth1 }, { imageUrl: Hearth2 }],
  icons: [
    { imageUrl: 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/javascript/javascript-original.svg', altText: 'Javascript' },
    { imageUrl: 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/react/react-original.svg', altText: 'React' },
    { imageUrl: 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/redux/redux-original.svg', altText: 'Redux' },
    { imageUrl: 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/nodejs/nodejs-plain.svg', altText: 'Node.js' },
    { imageUrl: 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/express/express-original.svg', altText: 'Express.js' },
    { imageUrl: 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/postgresql/postgresql-plain.svg', altText: 'PostgreSQL' },
    { imageUrl: 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/materialui/materialui-original.svg', altText: 'MaterialUI' },
    { imageUrl: 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/css3/css3-plain.svg', altText: 'CSS3' },
  ],
  credits: [{ title: 'Yilla Chen, Developer' }, { title: 'Caroline Spiezio, Developer' }, { title: 'Jean Chow, Developer' }, { title: 'Maxiel Mrvaljevic, Developer' }, { title: 'Fullstack Academy', url: 'https://fullstackacademy.com' }],
  feature: { snippet: 'A website with a custom cart & hashed authentication.', type: 'E-commerce Website' },
  info: {
    title: 'Hearth',
    description:
      "Hearth is a mock boutique e-commerce website that offers curated furniture collections targeted to busy people who want to create a home, but don't know where to start. <br /><br /> This website has login authentication using JWT & bcrypt, custom user and guest checkout experience, and offers unique admin controls.",
    url: 'https://github.com/04-gs-2109-no-signal-i-wep/hearth',
  },
};
