import React, { useState, useEffect } from 'react';
import Routes from './components/Routes';
import IPage from '../interface/page';
import Loading from './components/loading/Loading';
import { motion } from 'framer-motion';
import { fadeTransition, fadeVariant } from '../config/transition';
import '../css/main.css';

const App: React.FunctionComponent<IPage> = props => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let checkLoad = setInterval(function () {
      if (document.readyState === 'complete') {
        setLoading(false);
        clearInterval(checkLoad);
      }
    }, 1500);
    return () => console.log('Unmounting...');
  }, []);

  if (loading) {
    return <Loading />;
  }

  return (
    <main className="pt-5">
      <motion.div animate="in" initial="out" exit="out" variants={fadeVariant} transition={fadeTransition}>
        <Routes />
      </motion.div>
      <div className="fixed inset-0 bg-gradient-to-t from-berry/20 -z-[1]"></div>
    </main>
  );
};

export default App;
