import React from 'react';
import IPage from '../../interface/page';

const Footer: React.FunctionComponent<IPage> = props => {
  const footerIcons = [
    { url: 'https://github.com/yillachen', image_alt: 'GitHub', image: 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/github/github-original.svg' }
  ]

  return (
    <div className="font-mono text-sm flex flex-row justify-between py-4 border-berry border-t">
      <div className="container flex w-full justify-between">
        <ul className="flex flex-row gap-3">
          {footerIcons.map((icon, idx) => (
            <li key={idx}>
              <a href={icon.url} target="_blank" rel="noreferrer" className="footer-icon">
                <img src={icon.image} className="w-5 h-5" loading="lazy" alt={icon.image_alt} />
              </a>
            </li>
          ))}
        </ul>
        &copy; Yilla Chen {`${new Date().getFullYear()}`}
      </div>
    </div>
  );
};

export default Footer;
